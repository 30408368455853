.right-column {
  padding-left: 40px;
  padding-right: 40px;
  text-align: left;
}

.right-column {
  width: 100%;
}

@media (min-width: 768px) {

  .right-column {
    padding-left: 60px;
    padding-right: 60px;
    width: 65%;
    overflow-y: auto;
    max-height: 80vh;
    min-height: 80vh;

  }

}

.right-column {
  box-sizing: border-box;
/*  overflow-y: auto;
  max-height: 90vh; */
  scrollbar-width: thin;
  scrollbar-color: #333333 #1a1a1a;
}


.right-column::-webkit-scrollbar {
  width: 8px;
}

.right-column::-webkit-scrollbar-thumb {
  background-color: #1a1a1a;
}

.right-column::-webkit-scrollbar-track {
  background-color: #333333;
}

.title-section {
  position: relative;
  color: #78c7c7;
  margin-bottom: 20px;
  margin-top: 10px;
  font-size: 125%;
}

h3 {
  display: inline;
}

.line {
  /*position: absolute;
  top: 50%;
  left: 180px;*/
  width: calc(100% - 200px);
  height: 2px;
  background: linear-gradient(to right, rgb(82 199 199 / 23%), rgb(255 255 25 / 0%))
}