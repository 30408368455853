.blogpost-wrap {
    display: flex;
    transition: background-color 0.3s ease;
    padding: 10px;
    border-radius: 10px;
    font-size: 85%;
}

.blogpost-wrap:hover {
    background-color: rgba(255, 255, 255, 0.128);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.post-image {
    min-width: 28%;
    display: flex;
    overflow: hidden;
    opacity: 0.7;
    width: 25%;
    height: auto;
    align-items: center;

}

@media (min-width: 768px) {

    .post-image {
        min-width: 17%;
        width: 17%;
        height: auto;

    }
    .blogpost-wrap {
        font-size: initial;
    }
  
  }



.post-image img {
    width: 100%;
    height: auto
}

.blogpost-wrap:hover .post-image {
    opacity: 1;
}

.post-title {
    color: #78c7c7;
    font-size: 110%;
}

.post-data {
    margin-left: 20px;
}

.link-no-style {
    text-decoration: none;
    color: inherit;
}