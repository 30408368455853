.left-description {
  text-align: left;
}

.left-column {
  padding-right: 40px;
  padding-left: 40px;
}
.left-column {
  box-sizing: border-box;
}
.left-column {
  width: 100%;
}
@media (min-width: 768px) {

  .left-column {
    width: 35%;
    padding-right: 60px;
    padding-left: 60px;
  }

}
.name {
  color: white;
  transition: color 0.3s;
}

.name:hover {
  color: #78c7c7;
}