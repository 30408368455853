.career-wrap {
    display: flex;
    transition: background-color 0.3s ease;
    padding: 15px;
    border-radius: 10px;
}

.career-wrap:hover {
    background-color: rgba(255, 255, 255, 0.128);

    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.career-year {
    min-width: 15%;
    display: flex;
    opacity: 0.5;
    margin-right: 5px;
}

@media (max-width: 768px) {
    .career-year {
        min-width: 18%;
    }
}

.career-title {
    color: #78c7c7;
    font-size: 110%;
}

.career-content {
    margin-left: 5px;
}