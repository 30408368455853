.content-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}




.picture {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
}

@media (min-width: 768px) {

  .content-container {
    flex-direction: row;
  }

}