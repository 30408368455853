
  header ul {
    list-style-type: none;
  }
  
  header li {
    display: inline-block;
    margin-right: 20px;
    font-size: 18px;
    font-family: monospace, sans-serif; 
  }

  header li {
    cursor: pointer; 
  }

  @media (max-width: 768px) {
    header li {
      font-size: 16px;
    }
  }

  .header-el:hover {
    position: relative;
  }

  .header-el {
    position: relative;
    z-index: 1;
  }
  
  .header-el::before {
    content: '';
    position: absolute;
    width: calc(100% + 4px); 
    height: calc(100% + 4px); 
    background: linear-gradient(to top, rgba(255, 255, 255, 0.8), rgba(255, 1, 1, 0) 30%);
    border-radius: 80%;
    top: 5px;
    left: -2px;
    box-sizing: border-box;
    transform: scale(0);
    transition: transform 0.3s ease; 
    z-index: -1;

  }
  
  .header-el:hover::before {
    transform: scale(1); 
  }
  
  .download-cv{
    background: #b77600;
    border-radius: 10px;
    padding: 4px;
    padding-right: 7px;
    padding-left: 7px;
  }

  .link-no-style {
    text-decoration: none;
    color: inherit;
  }