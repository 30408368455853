body {
  margin: 0;
  padding: 0;
  background: radial-gradient(circle, #002d3f, #001f2b, #001f2b);
  overflow: hidden;
  font-size: 16px;
  overflow-y: auto;
  max-height: 80vh;
}

@media (max-width: 768px) {
  body {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  body {
    overflow-y: initial; 
    max-height: initial; 
  }
}

header {
  background-color: transparent;
  padding: 10px 0;
  text-align: right;
  padding-right: 60px;
}

@media (max-width: 768px) {
  header {
    padding-right: 0px;
  }
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

.lightness-gradient {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.footer{
  height: 11vh;
  padding-right: 60px;
  padding-left: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end; 
  opacity: 0.5;
}

@media (max-width: 768px) {
  .footer{
    padding-right: 40px;
    padding-left: 40px;
  }
}
