.bloglist-wrap {
    width: 100%;
}

.blog-content img {
    display: block;
    /* Ensure the image is a block-level element */
    margin: 0 auto;
    /* Center horizontally */
    max-width: 100%;
    /* Ensure the image doesn't exceed the div's width */
    height: auto;
    /* Maintain aspect ratio */
}

.blog-content a {
    font-weight: bold;
    /* Makes the text bold */
    text-decoration: underline;
    /* Underlines the text */
    color: inherit;
    /* Ensures the color is not changed */
}