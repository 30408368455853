.social-icon {
    display: 'inline-block';
    display: flex;
    justify-content: space-evenly;
}

.social-icon img {
    width: 35%;
    height: auto;
}

.social-icons {
    display: flex;
    justify-content: space-evenly;
    padding: 10%;
}

.social-icon {
    transition: transform 0.3s;
}

.social-icon:hover {
    transform: scale(1.2);
}